import moment from 'moment';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';

import {
  Booking,
  PaginationPayload,
  BookingDoctorInfoProfile,
  VisitHistoryBookingPublicInList,
} from '../../../typings';

export type PatientVisitHistory = VisitHistoryBookingPublicInList | Booking;

export type TableProps = {
  disableFullScreen?: boolean;
};

type Props = {
  patientId: string;
  request: <T extends PaginationPayload>(payload: T) => void;
  data: PatientVisitHistory[];
  count: number;
  isLoading: boolean;
  onVisitHistoryClick?: (visit: PatientVisitHistory) => void;
  getVisitHistoryPageLink: (visitHistory: PatientVisitHistory) => string;
} & TableProps;

const getFirstSpecialityNameFromProfile = (
  profile?: BookingDoctorInfoProfile,
) => {
  if (!profile || !profile?.specialities?.length) {
    return '-';
  }

  return profile.specialities[0].name;
};

const getBookingNote = (booking: PatientVisitHistory) => {
  if (booking['bookingNote']) {
    return booking['bookingNote'].note;
  }

  if (booking['bookingNotePublic']) {
    return booking['bookingNotePublic'].note;
  }

  return '-';
};

const BasePatientVisitHistoryTable: FC<Props> = ({
  patientId,
  request,
  data,
  count,
  isLoading,
  onVisitHistoryClick,
  getVisitHistoryPageLink,
  disableFullScreen = false,
}) => {
  const columns = useMemo<MRT_ColumnDef<PatientVisitHistory>[]>(
    () => [
      {
        accessorKey: 'endDate',
        accessorFn: (data) => [data.startDate, data.endDate],
        header: 'Date & Time',
        size: 150,
        Cell: (data) => {
          const [startDate, endDate] = data.renderedCellValue as string[];

          return (
            <div className="d-flex flex-column">
              <span className="fw-bold">
                {moment(startDate).format('D MMM YYYY')}
              </span>

              <span className="text-grey">
                {moment(startDate).format('h:mm A')} -{' '}
                {moment(endDate).format('h:mm A')}
              </span>
            </div>
          );
        },
      },
      {
        accessorFn: (data) =>
          `${data.doctor.firstName} ${data.doctor.lastName}`,
        header: 'Provider',
        size: 100,
        enableSorting: false,
      },
      {
        header: 'Credentials',
        accessorFn: (data) =>
          getFirstSpecialityNameFromProfile(data.doctor.profile),
        enableClickToCopy: true,
        size: 100,
        enableSorting: false,
      },
      {
        accessorFn: (data) => getBookingNote(data),
        header: 'Diagnosis',
        enableClickToCopy: true,
        size: 200,
        enableSorting: false,
        Cell: (data) => <div>{data.renderedCellValue ?? '-'}</div>,
      },
      // {
      //   accessorKey: '-',
      //   header: '',
      //   visibleInShowHideMenu: false,
      //   size: 20,
      //   enableColumnOrdering: false,
      //   enableSorting: false,
      //   Cell: () => (
      //     <FontAwesomeIcon
      //       className="float-right"
      //       icon={faChevronRight}
      //       style={{ color: '#F11B42' }}
      //     />
      //   ),
      // },
    ],
    [],
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');

  const updateQuery = useCallback(debounce(setQuery, 400), []);

  const handleSearch = (value = '') => {
    setSearchValue(value);

    updateQuery(value);
  };

  useEffect(() => {
    request({
      id: patientId,
      limit: pagination.pageSize,
      search: query,
      offset: pagination.pageIndex * pagination.pageSize,
    });
  }, [pagination.pageIndex, pagination.pageSize, query]);

  const isDataLoading = isLoading && !data.length;
  const isDataFetching = isLoading && data.length > 0;

  const table = useMaterialReactTable({
    columns,
    enableFullScreenToggle: !disableFullScreen,
    enableColumnActions: false,
    positionActionsColumn: 'last',
    manualPagination: true,
    positionGlobalFilter: 'left',
    rowCount: count,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20],
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: handleSearch,
    state: {
      pagination,
      globalFilter: searchValue,
      isLoading: isDataLoading,
      showProgressBars: isDataFetching,
    },
    muiTableBodyRowProps: ({ row }) => ({
      component: (data) => (
        <Link
          className={data.className}
          to={getVisitHistoryPageLink(row.original)}
          onClick={(e) => {
            if (onVisitHistoryClick) {
              e.preventDefault();
              onVisitHistoryClick(row.original);
            }
          }}
        >
          {data.children}
        </Link>
      ),
      sx: {
        cursor: 'pointer',
      },
    }),
    data,
  });

  return <MaterialReactTable table={table} />;
};

export default BasePatientVisitHistoryTable;

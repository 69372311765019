import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Creators as ErrorActions } from '../../modules/errors.module';
import { MMDError } from '../../utils/MMDError';
import { MyState } from '../../store';
import { FC, useEffect, useState } from 'react';

type Props = {
  error?: MMDError;
} & typeof ErrorActions;

const ErrorHandler: FC<Props> = ({ error, clearError }) => {
  const [message, setMessage] = useState(
    error
      ? error.message
      : 'What a shame... Someone forgot to write an error message!',
  );

  useEffect(() => {
    if (error) {
      window.scrollTo(0, 0);
      setMessage(error.message);
      console.log('error.message', error.message);
    }
  }, [error]);
  //error && error.message !== "undefined is not an object (evaluating 'h.firstName')"
  return error &&
    error.message !==
      "Cannot read properties of undefined (reading 'firstName')" &&
    error &&
    error.message !==
      "undefined is not an object (evaluating 'h.firstName')" ? (
    <Alert variant="danger" onClose={clearError} dismissible>
      <strong>Error:</strong> {message}
    </Alert>
  ) : null;
};

const mapStateToProps = (state: MyState) => ({
  error: state.error.error,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...ErrorActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);

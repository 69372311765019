import { SagaIterator } from 'redux-saga';
import Video, { Room } from 'twilio-video';
import { doc, onSnapshot } from 'firebase/firestore';
import { createActions, createReducer } from 'reduxsauce';
import { Observable, PromiseQueue } from '@mrnkr/promise-queue';
import { call, put, race, select, take } from 'redux-saga/effects';
import { MyAction, createSingleEventSaga } from '@mrnkr/redux-saga-toolbox';

import { MyState } from '../store';
import { AUTH_API_URL } from '../config';
import { MMDError } from '../utils/MMDError';
import { firestore } from '../utils/Firebase';
import { noOpAction } from '../utils/noOpAction';
import { BookingPublic, LocalTracks } from '../typings';
import { putAuthPublicInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';
import { ArgsWithHeaders, LocationChangeActionPayload } from '../utils/typings';
import { Creators as PublicWaitingRoomActions } from './videocall-public/waiting-room.module';

export const TOO_MANY_PARTICIPANTS_ERROR =
  'Room contains too many participants';

enum InputVideoDevices {
  FRONT = 'user',
  BACK = 'environment',
}

interface ActionTypes {
  REQUEST_VIDEO_TOKEN_CLIENT_PUBLIC: string;
  REQUEST_CONNECT_VIDEO_CLIENT_PUBLIC: string;
  CONNECT_VIDEO_CLIENT_PUBLIC: string;
  COMMIT_LOCAL_TRACKS_CLIENT_PUBLIC: string;
  COMMIT_LOCAL_VIDEO_TRACK_CLIENT_PUBLIC: string;
  COMMIT_IS_AUDIO_MUTED_CLIENT_PUBLIC: string;
  REQUEST_MUTE_LOCAL_AUDIO_TRACK_CLIENT_PUBLIC: string;
  REQUEST_UNMUTE_LOCAL_AUDIO_TRACK_CLIENT_PUBLIC: string;
  COMMIT_TWILIO_TOKEN_CLIENT_PUBLIC: string;
  REQUEST_DISCONNECT_VIDEO_CLIENT_PUBLIC: string;
  CLEAR_VIDEO_STATE_CLIENT_PUBLIC: string;
  VIDEO_CALL_LOG_EVENT_CLIENT_PUBLIC: string;
  EXTEND_VIDEO_CALL_CLIENT_PUBLIC: string;
  SUBSCRIBE_EXTENSION_CLIENT_PUBLIC: string;
  CHANGE_VIDEO_TRACK_CLIENT_PUBLIC: string;
  REQUEST_BOOKING_CLIENT_PUBLIC: string;
  COMMIT_BOOKING_CLIENT_PUBLIC: string;
  REQUEST_JOIN_PARTICIPANT_TO_THE_CALL: string;
  SUCCESS_JOIN_PARTICIPANT_TO_THE_CALL: string;
  SET_ERROR_VIDEO_CLIENT_PUBLIC: string;
}

type DownloadTwilioTokenPayload = {
  id: string;
  numberParticipant: string;
};

export enum ParticipantsTypes {
  PATIENT = 'patient',
  STUDENT = 'student',
  RELATIVE = 'relative',
  TRANSLATOR = 'translator',
  OTHER = 'other',
}

export type JoinParticipantPayload = {
  email?: string;
  bookingId: string;
  type: ParticipantsTypes | string;
};

interface ActionCreators {
  requestVideoTokenClientPublic: (
    payload: DownloadTwilioTokenPayload,
  ) => MyAction<{ token: string }>;
  requestConnectVideoClientPublic: () => MyAction<object>;
  connectVideoClientPublic: (payload: { room: any }) => MyAction<{ room: any }>;
  commitLocalTracksClientPublic: (
    payload: LocalTracks,
  ) => MyAction<LocalTracks>;
  commitLocalVideoTrackClientPublic: (
    payload: Pick<LocalTracks, 'video'>,
  ) => MyAction<Pick<LocalTracks, 'video'>>;
  commitIsAudioMutedClientPublic: (payload: boolean) => MyAction<boolean>;
  requestMuteLocalAudioTrackClientPublic: () => MyAction<void>;
  requestUnmuteLocalAudioTrackClientPublic: () => MyAction<void>;
  commitTwilioTokenClientPublic: (payload: {
    token: string;
  }) => MyAction<{ token: string }>;
  requestDisconnectVideoClientPublic: () => MyAction<object>;
  clearVideoStateClientPublic: () => MyAction<object>;
  videoCallLogEventClientPublic: (payload: {
    event: string;
  }) => MyAction<{ event: string }>;
  extendVideoCallClientPublic: () => MyAction<object>;
  subscribeExtensionClientPublic: (payload: {
    booking: BookingPublic;
  }) => MyAction<object>;
  changeVideoTrackClientPublic: () => MyAction<object>;
  commitBookingClientPublic: (payload: {
    booking: BookingPublic;
  }) => MyAction<{ booking: BookingPublic }>;
  requestBookingClientPublic: (payload: {
    bookingId: string;
  }) => MyAction<void>;
  requestJoinParticipantToTheCall: (
    payload: JoinParticipantPayload,
  ) => MyAction<void>;
  successJoinParticipantToTheCall: () => MyAction<void>;
}

export type VideoCallClientPublicState = {
  token?: string;
  room?: any;
  localTracks: LocalTracks;
  isAudioMuted: boolean;
  extended?: boolean;
  booking?: BookingPublic;
  clientType?: ParticipantsTypes | string;
};

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestVideoTokenClientPublic: ['payload'],
  requestConnectVideoClientPublic: ['payload'],
  connectVideoClientPublic: ['payload'],
  commitLocalTracksClientPublic: ['payload'],
  commitLocalVideoTrackClientPublic: ['payload'],
  commitIsAudioMutedClientPublic: ['payload'],
  requestMuteLocalAudioTrackClientPublic: [],
  requestUnmuteLocalAudioTrackClientPublic: [],
  commitTwilioTokenClientPublic: ['payload'],
  requestDisconnectVideoClientPublic: [],
  clearVideoStateClientPublic: [],
  videoCallLogEventClientPublic: ['payload'],
  extendVideoCallClientPublic: [],
  subscribeExtensionClientPublic: ['payload'],
  changeVideoTrackClientPublic: [],
  commitBookingClientPublic: ['payload'],
  requestBookingClientPublic: ['payload'],
  requestJoinParticipantToTheCall: ['payload'],
  successJoinParticipantToTheCall: ['payload'],
});

const initialState = {
  localTracks: null,
  isAudioMuted: false,
};

function commitTwilioTokenClientPublic(
  state: VideoCallClientPublicState,
  action: MyAction<{ token: string }>,
): VideoCallClientPublicState {
  return {
    ...state,
    ...action.payload,
  };
}

function connectVideoClientPublic(
  state: VideoCallClientPublicState,
  action: MyAction<any>,
): VideoCallClientPublicState {
  return {
    ...state,
    room: action.payload,
  };
}

function commitLocalTracksClientPublic(
  state: VideoCallClientPublicState,
  action: MyAction<LocalTracks>,
): VideoCallClientPublicState {
  return {
    ...state,
    localTracks: action.payload,
  };
}

function commitLocalVideoTrackClientPublic(
  state: VideoCallClientPublicState,
  action: MyAction<Pick<LocalTracks, 'video'>>,
): VideoCallClientPublicState {
  return {
    ...state,
    localTracks: {
      ...state.localTracks,
      video: action.payload.video,
    },
  };
}

function commitIsAudioMutedClientPublic(
  state: VideoCallClientPublicState,
  action: MyAction<boolean>,
): VideoCallClientPublicState {
  return {
    ...state,
    isAudioMuted: action.payload,
  };
}

function extendVideoCallClientPublic(
  state: VideoCallClientPublicState,
): VideoCallClientPublicState {
  return {
    ...state,
    extended: true,
  };
}

function clearStateClientPublic(): VideoCallClientPublicState {
  return initialState;
}

function commitBookingClientPublic(
  state: VideoCallClientPublicState,
  action: MyAction<BookingPublic>,
): VideoCallClientPublicState {
  return {
    ...state,
    booking: action.payload,
  };
}

function requestJoinParticipantToTheCall(
  state: VideoCallClientPublicState,
  action: MyAction<JoinParticipantPayload>,
): VideoCallClientPublicState {
  return {
    ...state,
    clientType: action.payload.type,
  };
}

export const videoCallClientPublicReducer =
  createReducer<VideoCallClientPublicState>(initialState, {
    [Types.CONNECT_VIDEO_CLIENT_PUBLIC]: connectVideoClientPublic,
    [Types.COMMIT_LOCAL_TRACKS_CLIENT_PUBLIC]: commitLocalTracksClientPublic,
    [Types.COMMIT_LOCAL_VIDEO_TRACK_CLIENT_PUBLIC]:
      commitLocalVideoTrackClientPublic,
    [Types.COMMIT_IS_AUDIO_MUTED_CLIENT_PUBLIC]: commitIsAudioMutedClientPublic,
    [Types.COMMIT_TWILIO_TOKEN_CLIENT_PUBLIC]: commitTwilioTokenClientPublic,
    [Types.EXTEND_VIDEO_CALL_CLIENT_PUBLIC]: extendVideoCallClientPublic,
    [Types.CLEAR_VIDEO_STATE_CLIENT_PUBLIC]: clearStateClientPublic,
    [Types.COMMIT_BOOKING_CLIENT_PUBLIC]: commitBookingClientPublic,
    [Types.REQUEST_JOIN_PARTICIPANT_TO_THE_CALL]:
      requestJoinParticipantToTheCall,
  });

async function downloadTwilioToken(
  payload: DownloadTwilioTokenPayload,
): Promise<{ response: boolean; token?: string }> {
  const allParticipantsCount = Number(payload.numberParticipant) + 1;

  const result = await fetch(
    `${AUTH_API_URL}/twilio/token-public-client/${payload.id}/${allParticipantsCount}`,
  );

  if (!result.ok) {
    throw new MMDError(
      'Something went wrong connecting you to your conference',
    );
  }

  return result.json();
}

function* changeVideoTrackClientPublic(): SagaIterator {
  const { video }: LocalTracks = yield select(
    (state: MyState) => state.videoCallClientPublic.localTracks,
  );

  const isFrontCameraActive = video.mediaStreamTrack
    .getCapabilities()
    .facingMode?.includes(InputVideoDevices.FRONT);

  yield call([video, 'restart'], {
    facingMode: isFrontCameraActive
      ? InputVideoDevices.BACK
      : InputVideoDevices.FRONT,
  });

  return { video };
}

async function downloadVideoCallPublic(payload: { bookingId: string }) {
  const response = await fetch(
    `${AUTH_API_URL}/bookings-public/${payload.bookingId}`,
  );

  const { booking } = await response.json();

  return booking;
}

async function joinParticipantToTheCall({
  headers,
  bookingId,
  ...payload
}: ArgsWithHeaders<JoinParticipantPayload & { name?: string }>) {
  const response = await fetch(
    `${AUTH_API_URL}/bookings-public/participant/${bookingId}`,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(payload),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new MMDError(error.msg);
  }

  return {
    role: payload.type,
    email: payload.email,
    ...(payload.name ? { name: payload.name } : {}),
  };
}

function* navigateAfterSuccessJoinParticipantToTheCall() {
  while (true) {
    const { payload } = yield take(Types.SUCCESS_JOIN_PARTICIPANT_TO_THE_CALL);
    yield put(PublicWaitingRoomActions.askToJoinRoom(payload));
  }
}

const requestJoinParticipantToTheCallWatcher = createSingleEventSaga<
  JoinParticipantPayload,
  any,
  MyAction<JoinParticipantPayload>
>({
  takeEvery: Types.REQUEST_JOIN_PARTICIPANT_TO_THE_CALL,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.successJoinParticipantToTheCall,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: joinParticipantToTheCall,
  // @ts-ignore
  *beforeAction(
    payload: Omit<JoinParticipantPayload, 'bookingId'>,
  ): SagaIterator {
    const { booking } = yield select(
      (state: MyState) => state.videoCallClientPublic,
    );

    return yield call(putAuthPublicInfoInArgs, {
      bookingId: booking?.id,
      ...payload,
    });
  },
});

const requestVideoCallTokenClientPublicWatcher = createSingleEventSaga<
  { id: string },
  { token: string },
  MyAction<{ id: string }>
>({
  takeEvery: Types.REQUEST_VIDEO_TOKEN_CLIENT_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTwilioTokenClientPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadTwilioToken,
});

const requestBookingClientPublicWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  { booking: BookingPublic },
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.REQUEST_BOOKING_CLIENT_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitBookingClientPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadVideoCallPublic,
});

function* connect({ token, booking }): SagaIterator {
  try {
    const bookingId = booking.eventId;

    const participantsResponse = yield call(
      fetch,
      `${AUTH_API_URL}/twilio/view-participants-public-client/${bookingId}`,
    );

    const { participants: participantsList } = yield call([
      participantsResponse,
      'json',
    ]);

    const bookingParticipantsNumber = Number(booking?.numberParticipant);

    const clientParticipants = participantsList.filter(
      (participant) => participant !== booking?.doctorId,
    );

    if (clientParticipants.length >= bookingParticipantsNumber) {
      throw new MMDError(TOO_MANY_PARTICIPANTS_ERROR);
    }

    return yield call([Video, 'connect'], token, { name: bookingId });
  } catch (err) {
    yield put(
      Creators.videoCallLogEventClientPublic({
        event: 'onRoomDidFailToConnect',
      }),
    );

    throw new MMDError(err.message);
  }
}

const requestConnectVideoClientPublicWatcher = createSingleEventSaga<
  object,
  any,
  MyAction<object>
>({
  takeEvery: Types.COMMIT_TWILIO_TOKEN_CLIENT_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.connectVideoClientPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: connect,
  // @ts-ignore
  *beforeAction(payload: { token: string }): SagaIterator {
    const { booking } = yield select(
      (state: MyState) => state.videoCallClientPublic,
    );

    return { ...payload, booking };
  },
});

const requestLocalTrackClientPublicWatcher = createSingleEventSaga<
  object,
  { localTrack: any },
  MyAction<object>
>({
  takeEvery: Types.CONNECT_VIDEO_CLIENT_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitLocalTracksClientPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  *action(): SagaIterator {
    const room: Room = yield select(
      (state: MyState) => state.videoCallClientPublic.room,
    );

    const localVideoTracks = Array.from(
      room.localParticipant.videoTracks.values(),
    ).map((track) => track.track);

    const localAudioTrack = Array.from(
      room.localParticipant.audioTracks.values(),
    ).map((track) => track.track);

    return {
      video: localVideoTracks[0],
      audio: localAudioTrack[0],
    };
  },
});

const requestChangeLocalTrackClientPublicWatcher = createSingleEventSaga<
  void,
  any,
  MyAction<void>
>({
  takeEvery: Types.CHANGE_VIDEO_TRACK_CLIENT_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitLocalVideoTrackClientPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: changeVideoTrackClientPublic,
});

const requestDisconnectClientPublicWatcher = createSingleEventSaga<
  object,
  void,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_DISCONNECT_VIDEO_CLIENT_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.clearVideoStateClientPublic,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.clearError,
  *action(): SagaIterator {
    const { room, localTrack } = yield select(
      (state: MyState) => state.videoCallClientPublic,
    );
    room.disconnect();
    localTrack.stop();
    localTrack.detach().forEach((detachedElement) => {
      detachedElement.remove();
    });
  },
});

const requestMuteLocalAudioTrackClientPublicWatcher = createSingleEventSaga<
  boolean,
  void,
  MyAction<boolean>
>({
  takeEvery: Types.REQUEST_MUTE_LOCAL_AUDIO_TRACK_CLIENT_PUBLIC,
  loadingAction: noOpAction,
  commitAction: Creators.commitIsAudioMutedClientPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.clearError,
  *action(): SagaIterator {
    const { audio }: LocalTracks = yield select(
      (state: MyState) => state.videoCallClientPublic.localTracks,
    );

    audio.disable();

    return true;
  },
});

const requestUnmuteLocalAudioTrackClientPublicWatcher = createSingleEventSaga<
  boolean,
  void,
  MyAction<boolean>
>({
  takeEvery: Types.REQUEST_UNMUTE_LOCAL_AUDIO_TRACK_CLIENT_PUBLIC,
  loadingAction: noOpAction,
  commitAction: Creators.commitIsAudioMutedClientPublic,
  successAction: noOpAction,
  errorAction: ErrorActions.clearError,
  *action(): SagaIterator {
    const { audio }: LocalTracks = yield select(
      (state: MyState) => state.videoCallClientPublic.localTracks,
    );

    audio.enable();

    return false;
  },
});

function* logEventClientPublic(): SagaIterator {
  while (true) {
    try {
      const {
        payload: { event: action },
      } = yield take(Types.VIDEO_CALL_LOG_EVENT_CLIENT_PUBLIC);
      const { booking } = yield select(
        (state: MyState) => state.videoCallClientPublic,
      );

      const { headers } = yield call(putAuthPublicInfoInArgs, {});

      const result = yield call(
        fetch,
        `${AUTH_API_URL}/bookings-public/${booking.eventId}/timeTracking`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify({ action }),
        },
      );
      if (!result.ok) {
        throw new MMDError('An error has occurred');
      }
    } catch (err) {
      yield put(ErrorActions.setError(err));
    }
  }
}

function extensionStatus(args: {
  bookingId: number;
  doctorId: string;
  patientId: string;
}): Observable<boolean> {
  return {
    subscribe: (next, error) => {
      const bookingExtensionsRef = doc(
        firestore,
        `bookingExtensions/${args.bookingId}/doctors/${args.doctorId}/patients/${args.patientId}`,
      );

      const unsubscribe = onSnapshot(
        bookingExtensionsRef,
        (snap) => {
          if (snap.exists()) {
            next(snap.exists());
          }
        },
        error,
      );
      return { unsubscribe };
    },
  };
}

function* extensionStatusClientPublicWatcher(): SagaIterator {
  while (true) {
    const action = yield take(Types.SUBSCRIBE_EXTENSION_CLIENT_PUBLIC);
    const booking: BookingPublic = action.payload.booking;
    const { id: bookingId, doctorId } = booking;
    const publicParticipant: string = 'publicParticipant';
    try {
      // Will emit only once - REMEMBER
      const promiseQueue = new PromiseQueue<boolean>(
        extensionStatus({
          bookingId: parseInt(String(bookingId), 10),
          doctorId,
          patientId: publicParticipant,
        }),
      );
      const { leave } = yield race({
        leave: take(
          (action: MyAction<{ event: string }>) =>
            action.type === Types.CLEAR_VIDEO_STATE_CLIENT_PUBLIC,
        ),
      });
      if (leave) {
        promiseQueue.cancel(new Error());
        continue;
      }
    } catch (err) {
      console.log('error', err);
    }
  }
}

export const videoCallClientPublicSagas = [
  logEventClientPublic,
  requestBookingClientPublicWatcher,
  extensionStatusClientPublicWatcher,
  requestLocalTrackClientPublicWatcher,
  requestDisconnectClientPublicWatcher,
  requestJoinParticipantToTheCallWatcher,
  requestConnectVideoClientPublicWatcher,
  requestVideoCallTokenClientPublicWatcher,
  requestChangeLocalTrackClientPublicWatcher,
  navigateAfterSuccessJoinParticipantToTheCall,
  requestMuteLocalAudioTrackClientPublicWatcher,
  requestUnmuteLocalAudioTrackClientPublicWatcher,
];

export const selectVideoCallClientPublicState = (state: MyState) =>
  state.videoCallClientPublic;

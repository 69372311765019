import {
  createSingleEventSaga,
  MyAction,
  composeSagas,
} from '@mrnkr/redux-saga-toolbox';
import { Observable, PromiseQueue } from '@mrnkr/promise-queue';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';
import { call, take, select, put, race } from 'redux-saga/effects';
import Video, { createLocalVideoTrack } from 'twilio-video';
import { API_URL, AUTH_API_URL } from '../config';
import { ArgsWithHeaders, LocationChangeActionPayload } from '../utils/typings';
import { Creators as LoadingActions } from './loading.module';
import { Creators as ErrorActions } from './errors.module';
import { MMDError } from '../utils/MMDError';
import { putAuthInfoInArgs } from './auth.module';
import { onRoute, extractRouteParams } from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { firestore } from '../utils/Firebase';
import { MyState } from '../store';
import { Booking } from '../typings';
import { doc, onSnapshot } from 'firebase/firestore';
import { selectBookingById } from './bookings.module';

interface ActionTypes {
  REQUEST_CONNECT_VIDEO: string;
  CONNECT_VIDEO: string;
  COMMIT_LOCAL_TRACK: string;
  COMMIT_TWILIO_TOKEN: string;
  REQUEST_DISCONNECT_VIDEO: string;
  CLEAR_VIDEO_STATE: string;
  VIDEO_CALL_LOG_EVENT: string;
  EXTEND_VIDEO_CALL: string;
  SUBSCRIBE_EXTENSION: string;
}
interface ActionCreators {
  requestConnectVideo: () => MyAction<object>;
  connectVideo: (payload: { room: any }) => MyAction<{ room: any }>;
  commitLocalTrack: (payload: {
    localTrack: any;
  }) => MyAction<{ localTrack: any }>;
  commitTwilioToken: (payload: {
    token: string;
    bookingId: string;
  }) => MyAction<{ token: string; bookingId: string }>;
  requestDisconnectVideo: () => MyAction<object>;
  clearVideoState: () => MyAction<object>;
  videoCallLogEvent: (payload: {
    event: string;
  }) => MyAction<{ event: string }>;
  extendVideoCall: () => MyAction<object>;
  subscribeExtension: (payload: { booking: Booking }) => MyAction<object>;
}
export type VideoCallState = {
  bookingId?: string;
  token?: string;
  room?: any;
  localTrack?: any;
  extended?: boolean;
};
export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestConnectVideo: ['payload'],
  connectVideo: ['payload'],
  commitLocalTrack: ['payload'],
  commitTwilioToken: ['payload'],
  requestDisconnectVideo: [],
  clearVideoState: [],
  videoCallLogEvent: ['payload'],
  extendVideoCall: [],
  subscribeExtension: ['payload'],
});
const initialState = {};
function commitTwilioToken(
  state: VideoCallState,
  action: MyAction<{ token: string; bookingId: string }>,
): VideoCallState {
  return {
    ...state,
    ...action.payload,
  };
}
function connectVideo(
  state: VideoCallState,
  action: MyAction<{ room: any; bookingId: string }>,
): VideoCallState {
  return {
    ...state,
    room: action.payload.room,
  };
}
function commitLocalTrack(
  state: VideoCallState,
  action: MyAction<{ localTrack: any }>,
): VideoCallState {
  return {
    ...state,
    localTrack: action.payload.localTrack,
  };
}
function extendVideoCall(state: VideoCallState): VideoCallState {
  return {
    ...state,
    extended: true,
  };
}
function clearState(): VideoCallState {
  return initialState;
}
export const videoCallReducer = createReducer<VideoCallState>(initialState, {
  [Types.CONNECT_VIDEO]: connectVideo,
  [Types.COMMIT_LOCAL_TRACK]: commitLocalTrack,
  [Types.COMMIT_TWILIO_TOKEN]: commitTwilioToken,
  [Types.EXTEND_VIDEO_CALL]: extendVideoCall,
  [Types.CLEAR_VIDEO_STATE]: clearState,
});
async function downloadTwilioToken({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<
  { response: true; token: string } | { response: false }
> {
  const result = await fetch(`${AUTH_API_URL}/twilio/token/${payload.id}`, {
    headers,
    method: 'GET',
  });
  if (!result.ok) {
    throw new MMDError(
      'Something went wrong connecting you to your conference',
    );
  }
  return result.json();
}
const requestVideoCallTokenWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  { token: string; bookingId: string },
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/video-call/:id'),
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitTwilioToken,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadTwilioToken,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: string },
    ArgsWithHeaders<{ id: string }>
  >(
    // @ts-ignore
    extractRouteParams('/video-call/:id'),
    putAuthInfoInArgs,
  ),
  // @ts-ignore
  *afterAction(
    res: { response: true; token: string } | { response: false },
    payload: LocationChangeActionPayload,
  ): SagaIterator {
    if (res.response) {
      const params = yield call(extractRouteParams('/video-call/:id'), payload);
      return { token: res.token, bookingId: params.id };
    }
  },
});
function* connect({ bookingId, token }): SagaIterator {
  try {
    const room = yield call([Video, 'connect'], token, { name: bookingId });

    return {
      room,
      bookingId,
    };
  } catch (err) {
    console.log(err);
    yield put(Creators.videoCallLogEvent({ event: 'onRoomDidFailToConnect' }));
    throw new MMDError(
      'We were unable to connect you to your meeting for we do not have permission to use your camera and/or microphone. ' +
        "Please, grant us permission to use them or else we won't be able to connect.",
    );
  }
}

function* createLocalTrack({
  headers,
  booking,
}: ArgsWithHeaders<{ booking: Booking }>) {
  const localTrack = yield call(createLocalVideoTrack);

  if (!booking.doctorAttended) {
    yield call(fetch, `${API_URL}/bookings/${booking.eventId}`, {
      headers,
      method: 'PUT',
      body: JSON.stringify({ doctorAttended: true }),
    });
  }

  return {
    localTrack,
  };
}

const requestConnectVideoWatcher = createSingleEventSaga<
  object,
  any,
  MyAction<object>
>({
  takeEvery: Types.COMMIT_TWILIO_TOKEN,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.connectVideo,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: connect,
  // @ts-ignore
  *beforeAction(payload: { bookingId: string }): SagaIterator {
    const token = yield select((state: MyState) => state.videoCall.token);
    return { ...payload, token };
  },
});

const requestLocalTrackWatcher = createSingleEventSaga<
  object,
  { localTrack: any },
  MyAction<object>
>({
  takeEvery: Types.CONNECT_VIDEO,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitLocalTrack,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: createLocalTrack,
  // @ts-ignore
  *beforeAction({ bookingId }): SagaIterator {
    const { headers } = yield call(putAuthInfoInArgs, {});

    const booking = yield select((state: MyState) =>
      selectBookingById(state, bookingId),
    );

    return {
      booking,
      headers,
    };
  },
});
const requestDisconnectWatcher = createSingleEventSaga<
  object,
  void,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_DISCONNECT_VIDEO,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.clearVideoState,
  successAction: noOpAction,
  errorAction: ErrorActions.clearError,
  *action(): SagaIterator {
    const { room, localTrack } = yield select(
      (state: MyState) => state.videoCall,
    );
    console.log('room.disconnect()');
    room.disconnect();
    localTrack.stop();
    localTrack.detach().forEach((detachedElement) => {
      detachedElement.remove();
    });
  },
});

function* logEvent(): SagaIterator {
  while (true) {
    try {
      const {
        payload: { event: action },
      } = yield take(Types.VIDEO_CALL_LOG_EVENT);
      const { bookingId } = yield select((state: MyState) => state.videoCall);
      const { headers } = yield call(putAuthInfoInArgs, {});
      const result = yield call(
        fetch,
        `${AUTH_API_URL}/bookings/${bookingId}/timeTracking`,
        {
          headers,
          method: 'POST',
          body: JSON.stringify({ action }),
        },
      );
      if (!result.ok) {
        throw new MMDError('An error has occurred');
      }
    } catch (err) {
      yield put(ErrorActions.setError(err));
    }
  }
}

function extensionStatus(args: {
  bookingId: number;
  doctorId: string;
  patientId: string;
}): Observable<boolean> {
  return {
    subscribe: (next, error) => {
      const bookingExtensionsRef = doc(
        firestore,
        'bookingExtensions',
        `${args.bookingId}`,
        'doctors',
        args.doctorId,
        'patients',
        args.patientId,
      );

      const unsubscribe = onSnapshot(
        bookingExtensionsRef,
        (snap) => {
          if (snap.exists()) {
            next(snap.exists());
          }
        },
        error,
      );
      return { unsubscribe };
    },
  };
}

function* extensionStatusWatcher(): SagaIterator {
  while (true) {
    const action = yield take(Types.SUBSCRIBE_EXTENSION);
    const booking: Booking = action.payload.booking;
    const { id: bookingId, doctorId, patientId } = booking;
    try {
      // Will emit only once - REMEMBER
      const promiseQueue = new PromiseQueue<boolean>(
        extensionStatus({
          bookingId: parseInt(bookingId, 10),
          doctorId,
          patientId,
        }),
      );
      const { leave, extend } = yield race({
        extend: call(promiseQueue.next),
        leave: take(
          (action: MyAction<{ event: string }>) =>
            action.type === Types.CLEAR_VIDEO_STATE,
        ),
      });
      if (leave) {
        promiseQueue.cancel(new Error());
        continue;
      }
      if (extend && extend.value) {
        yield put(Creators.extendVideoCall());
        promiseQueue.cancel(new Error());
      }
    } catch (err) {
      console.log('error', err);
    }
  }
}

export const videoCallSagas = [
  requestVideoCallTokenWatcher,
  requestConnectVideoWatcher,
  requestLocalTrackWatcher,
  requestDisconnectWatcher,
  extensionStatusWatcher,
  logEvent,
];

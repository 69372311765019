import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Button, Image } from 'react-bootstrap';
import moment from 'moment';
import { push } from 'redux-first-history';
import { Booking } from '../../typings';
import AwaitingPatientMessage from './video-call-view/AwaitingPatientMessage';
import FileInput from './video-call-view/FileInput';
import { MyState } from '../../store';
import { Creators as TemplatesActions } from '../../modules/template.module';
import { FC, useCallback, useEffect, useState } from 'react';

type Props = {
  shrinkHorizontally: boolean;
  booking?: Booking;
  room: any;
  localTrack: any;
  extended: boolean;
  eventLogger: (event: string) => void;
  saveFile: (file: File) => void;
  onRequestSidePanel: () => void;
  cleanup: () => void;
  push: (path: string) => void;
  subscribeExtension: (payload: { booking: Booking }) => void;
  setFullScreen: () => void;
  fullScreen: boolean;
  isSidePanelVisible: boolean;
  isStudent: boolean;
  templates: any;
  requestTemplates: any;
} & typeof TemplatesActions;

// TODO: this is not a elegant solution but if we use let the valirable expire after unmount the component
let timerControl = true;

// TODO: check if this condition is force in a production environment.
const ExtendVideoCallPopupCondition = false;

const VideoCallView: FC<Props> = ({
  isStudent,
  isSidePanelVisible,
  extended,
  subscribeExtension,
  shrinkHorizontally = false,
  booking,
  room,
  localTrack,
  eventLogger,
  saveFile,
  cleanup,
  onRequestSidePanel,
  push,
  setFullScreen,
  fullScreen,
  templates,
  requestTemplates,
}) => {
  const start = booking && moment(booking.event.start.dateTime);
  const end =
    booking &&
    (ExtendVideoCallPopupCondition && extended
      ? moment(booking.event.end.dateTime).add(5, 'minutes')
      : moment(booking.event.end.dateTime));
  const [isPatientConnected, setPatientConnected] = useState(false);
  const [timer, setTimer] = useState(moment());

  const participantConnected = useCallback(
    (participant) => {
      setPatientConnected(true);
      eventLogger(`Patient added to the meeting`);

      // const div = document.getElementById('remote-track');

      const trackSubscribed = () => (track) => {
        if (track && track.attach) {
          const div = document.getElementById('remote-track');
          div.appendChild(track.attach());
        }
      };

      const trackUnsubscribed = (track) => {
        if (track && track.detach) {
          track.detach().forEach((element) => element.remove());
        }
      };

      participant.on('trackSubscribed', trackSubscribed());
      participant.on('trackUnsubscribed', trackUnsubscribed);

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          trackSubscribed()(publication.track);
        }
      });
    },
    [eventLogger, setPatientConnected],
  );

  const participantDisconnected = useCallback(() => {
    if (document.getElementById('remote-track').children.length === 0) {
      setPatientConnected(false);
    }
    eventLogger('Patient removed from the meeting');
  }, [eventLogger, setPatientConnected]);

  const callTimer = () => {
    setTimer(moment());
    if (timerControl && timer < moment(end)) {
      setTimeout(() => callTimer(), 1000);
    }
  };

  useEffect(() => {
    requestTemplates();

    if (!room || !localTrack) {
      return;
    }
    if (booking) {
      subscribeExtension({ booking });
    }
    eventLogger('onRoomDidConnect');
    room.participants.forEach(participantConnected);

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    timerControl = true;
    callTimer();

    const container = document.getElementById('local-track');
    container.appendChild(localTrack.attach());

    return () => {
      timerControl = false;
      eventLogger('onRoomDidDisconnect');
      cleanup();
    };
  }, [
    room,
    localTrack,
    eventLogger,
    cleanup,
    participantConnected,
    participantDisconnected,
    booking,
    subscribeExtension,
  ]);

  const leave = useCallback(() => {
    try {
      timerControl = false;
      eventLogger('Leaving the meeting by disconnect');
      cleanup();
      if (isStudent) {
        push('/dashboard');
      } else {
        push(`/feedback/${booking.patientId}/${booking.id}`);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  }, [cleanup, eventLogger, booking, push, isStudent]);

  const formatTimer = (start, endTime) => {
    const newEnd = moment(endTime);
    const min = moment.duration(newEnd.diff(start)).minutes();
    const sec = moment.duration(newEnd.diff(start)).seconds();
    return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
  };

  return (
    <Col
      className={`${shrinkHorizontally ? 'd-none d-sm-block' : ''} p-0`}
      sm={shrinkHorizontally ? 6 : 12}
    >
      {isPatientConnected ? (
        <div id="remote-track" className="remote-track remote-black" />
      ) : (
        <div id="remote-track" className="remote-black full-height" />
      )}
      <div id="local-track" className="local-track" />
      <div className="btn-leave-background" onClick={leave}>
        <div className="btn-leave" />
      </div>

      <h6 className="video-call-title">
        {!isSidePanelVisible &&
          booking &&
          booking.peerName &&
          `Video Call with ${booking.peerName}`}
      </h6>

      {timer < end && isPatientConnected && start < timer && (
        <div className="btn-countdown-background">
          {formatTimer(timer, end)}
          {ExtendVideoCallPopupCondition && extended && (
            <>
              <br />
              <small className="">extended by patient</small>
            </>
          )}
        </div>
      )}
      {!isStudent && (
        <FileInput saveFile={saveFile}>
          <div
            className={`btn-plus-background ${
              shrinkHorizontally ? 'btn-plus-background__left' : ''
            }`}
          >
            <Image src="/assets/plus-icon.png" alt="plus icon" />
          </div>
        </FileInput>
      )}

      <Button
        variant="secondary"
        onClick={setFullScreen}
        className={`btn-full-view-background ${
          shrinkHorizontally ? 'btn-full-view-background__left' : ''
        }`}
      >
        {fullScreen ? 'Small View' : 'Full View'}
      </Button>

      {!isStudent && (
        <div
          className={`right-panel-arrow ${
            shrinkHorizontally ? 'right-panel-arrow__side-open' : ''
          }`}
          onClick={onRequestSidePanel}
        />
      )}
      {!isPatientConnected && (
        <AwaitingPatientMessage
          startDate={booking && booking.event.start.dateTime}
        />
      )}
    </Col>
  );
};

const mapStateToProps = (state: MyState) => ({
  isStudent: !!(state.provider && state.provider.teacher),
  templates: state.templates.entities,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...TemplatesActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VideoCallView);

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import DoctorProfile from '../components/DoctorProfile';
import { useBoundedActions } from '../hooks/useBoundedActions';
import FeedbackComponentPublic from './feedback/FeedbackComponentPublic';
import {
  selectBookingsPublicState,
  Creators as BookingsPublicActions,
} from '../modules/bookings-public.module';

const FeedbackViewPublic = () => {
  const params = useParams<{ id: string }>();
  const docProfileElem = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string | undefined>();

  const { booking } = useSelector(selectBookingsPublicState);

  const { clearBookingPublic, requestBookingPublic } = useBoundedActions(
    BookingsPublicActions,
  );

  useLayoutEffect(() => {
    if (!docProfileElem?.current) return;
    if (docProfileElem.current.clientHeight !== 0) {
      setHeight(docProfileElem.current.clientHeight + 'px');
    }
  }, []);

  useEffect(() => {
    if (booking) {
      return;
    }

    requestBookingPublic({ eventId: params.id });

    return clearBookingPublic;
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mb-3 mt-5">
          <h5>Video Call Feedback</h5>
        </Col>
      </Row>
      <Row className="justify-content-center align-middle pt-1 pb-1">
        <Col xs={12} sm={12} md={12} lg={3}>
          <div ref={docProfileElem} className="w-100">
            <DoctorProfile />
          </div>
        </Col>
        <Col style={{ height }} xs={12} sm={12} md={12} lg={8}>
          {booking && (
            <FeedbackComponentPublic
              booking={booking}
              // edit={params.edit === 'edit'}
              edit={false}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FeedbackViewPublic;

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState, useEffect, useMemo, FC } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import { MyState } from '../../store';
import NotesTextArea, {
  noteSections,
  getNotesString,
} from '../../components/notes/notesTextArea';
import { BookingPublic } from '../../typings';
import { Creators as TemplatesActions } from '../../modules/template.module';
import { Creators as BookingsPublicActions } from '../../modules/bookings-public.module';

// const MAX_HOURS_TO_EDIT_NOTES = 24;

type Props = {
  booking: BookingPublic;
  edit: boolean;
  templates: any;
  requestTemplates: any;
} & typeof BookingsPublicActions &
  typeof TemplatesActions;

// const isFeedbackEditable = (appointmentDate: Date): boolean => {
//   return (
//     moment().utc().diff(moment(appointmentDate), 'hours') <
//     MAX_HOURS_TO_EDIT_NOTES
//   );
// };

const FeedbackComponentPublic: FC<Props> = ({
  booking,
  updateBookingNotePublic,
  sendFeedbackPublic,
  templates,
  requestTemplates,
}) => {
  const [showNotes, setShowNotes] = useState(false);
  const [showFeedBack, setShowFeedback] = useState(true);
  const [rating, setRating] = useState(Number(booking.doctorRating ?? '0'));
  const [comment, setComment] = useState(booking.doctorComment ?? '');
  const [noteEditableMode] = useState(false);
  const [feedbackViewOnly] = useState(false);
  const [notesObject, setNotesObject] = useState<noteSections[]>([
    { id: 0, name: 'Basic', value: '', noTitle: true },
  ]);
  const [noteTemplates, setNoteTemplates] = useState([]);
  const [templateSelected, setTemplateSelected] = useState(
    noteTemplates && noteTemplates[0],
  );
  const isSavedNote = Boolean(booking?.bookingNotePublic?.note);

  const handlerSelectTemplate = (value) => {
    switch (value) {
      case '-1':
        setTemplateSelected('loaded');
        break;
      case '0':
        setTemplateSelected('basic');
        break;
      default:
        try {
          setTemplateSelected(templates.entities[value]);
        } catch (e) {
          console.log(e);
          setTemplateSelected('basic');
        }
    }
  };

  useMemo(() => {
    if (!templateSelected || templateSelected == 'loaded') {
      if (isSavedNote) {
        setNotesObject([
          {
            id: 0,
            name: 'Saved Note',
            value: booking.bookingNotePublic.note,
            noTitle: true,
          },
        ]);
      }
    }
  }, [booking]);

  const renderNotes = () => {
    setShowNotes(true);
    setShowFeedback(false);
  };

  const updateNotes = () => {
    const noteString = getNotesString();
    if (notesObject[0]?.value !== noteString) {
      updateBookingNotePublic({
        id: booking.eventId,
        bookingNotePublic: noteString,
        // redirectURL: !noteEditableMode && `/dashboard`,
      });
    }
    setShowFeedback(true);
    setShowNotes(false);
  };

  const sendComment = async () => {
    sendFeedbackPublic({ id: booking.eventId, rating, comment });
  };

  useEffect(() => {
    if (templateSelected) {
      switch (templateSelected) {
        case 'loaded':
          isSavedNote &&
            setNotesObject([
              {
                id: 0,
                name: 'Saved Note',
                value: booking.bookingNotePublic.note,
                noTitle: true,
              },
            ]);
          break;
        case 'basic':
          setNotesObject([{ id: 0, name: 'Basic', value: '', noTitle: true }]);
          break;
        default:
          setNotesObject(
            templateSelected &&
              templateSelected.sections.map((sec) => ({
                id: sec.id,
                name: sec.name,
                value: '',
              })),
          );
      }
    }
  }, [templateSelected]);

  useEffect(() => {
    setNoteTemplates(
      templates && templates.ids.map((id) => templates.entities[id]),
    );
  }, [templates]);

  useEffect(() => {
    requestTemplates();
  }, []);

  return (
    <Container className="d-flex flex-grow-1 h-100" fluid>
      <Row className="d-flex justify-content-center w-100">
        <Col className="d-flex chat" xs={12}>
          {showFeedBack && !noteEditableMode && !feedbackViewOnly && (
            <Row className="d-flex flex-column justify-content-between align-items-stretch flex-grow-1">
              <div style={{ height: '20%' }}>
                <Col xs={12}>
                  <h2 className="mt-3 mb-4 text-center">Feedback to patient</h2>
                </Col>
                <div>
                  <div className="d-flex ">
                    <div className=" margin-left-15 vcenter margin-right-30">
                      Your Feedback
                    </div>
                    <StarRatingComponent
                      starCount={5}
                      value={rating}
                      name={'feedback'}
                      onStarClick={setRating}
                      renderStarIcon={() => <i className="feedback-star">★</i>}
                    />
                  </div>
                </div>
              </div>
              <div className="complete-height" style={{ height: '50%' }}>
                <Col className="mt-2 mb-2" xs={12}>
                  Your comment
                </Col>
                <Col
                  className="mt-2 mb-2 complete-height"
                  xs={12}
                  style={{ height: '90%' }}
                >
                  <textarea
                    style={{ height: '100%' }}
                    className="form-control complete-height"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </Col>
              </div>
              <div style={{ height: '30%' }}>
                <Col className="my-5 text-center" xs={12}>
                  <h6 className="text-center text-muted">
                    You can choose recommendations
                  </h6>
                </Col>

                <Col xs={12}>
                  <span className="ml-3 form-label">Select template note:</span>
                  <select
                    onChange={(e) => handlerSelectTemplate(e.target.value)}
                    className="w-100 form-control"
                  >
                    {isSavedNote && (
                      <option key={-1} value={-1} selected>
                        Saved Note
                      </option>
                    )}
                    <option key={0} value={0}>
                      Basic Template
                    </option>
                    {noteTemplates.map((template) => (
                      <option
                        key={template.id}
                        value={template.id}
                        selected={
                          templateSelected &&
                          templateSelected != 'basic' &&
                          templateSelected.id == template.id
                        }
                      >
                        {template.title}
                      </option>
                    ))}
                  </select>
                </Col>

                <Col xs={12}>
                  <Button
                    className="w-100 mb-2 mt-2"
                    variant="primary"
                    onClick={renderNotes}
                  >
                    Write down notes for patient
                  </Button>
                </Col>

                <Col xs={12}></Col>

                <Col xs={12}>
                  <Link
                    style={{ display: 'contents' }}
                    className="w-100"
                    to={{
                      pathname: `/dashboard`,
                    }}
                  >
                    <Button
                      className="w-100 mb-4 mt-2"
                      variant="outline-primary"
                      onClick={sendComment}
                    >
                      Send comment
                    </Button>
                  </Link>
                </Col>
              </div>
            </Row>
          )}
          {(showNotes || noteEditableMode) && (
            <div className="w-100 d-flex flex-column">
              {!noteEditableMode && (
                <Row>
                  <img
                    className="mx-2 my-3"
                    style={{ objectFit: 'contain', cursor: 'pointer' }}
                    height="32"
                    width="32"
                    src="/assets/left-arrow.png"
                    alt="Previous step"
                    onClick={() => {
                      setShowFeedback(true);
                      setShowNotes(false);
                      //Here because the templateSelect resets but not the templateSelectedState
                      setTemplateSelected(isSavedNote ? 'loaded' : 'basic');
                    }}
                  />
                </Row>
              )}

              <Row className="fix-notes-oversized">
                <Col className="d-flex text-center">
                  <Form.Group
                    controlId="exampleForm.ControlTextarea1"
                    className="d-flex flex-column w-100"
                  >
                    <Form.Label
                      className={`mb-4 ${noteEditableMode && 'mt-4'}`}
                    >
                      Write notes for patient
                    </Form.Label>
                    {<h1>{templateSelected && templateSelected.title} </h1>}
                    <NotesTextArea notes={notesObject} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="w-100 mb-2"
                    variant="primary"
                    onClick={updateNotes}
                  >
                    Send note
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  templates: state.templates,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...BookingsPublicActions,
      ...TemplatesActions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeedbackComponentPublic);
